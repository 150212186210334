import type { VideoEdge } from 'api';
import { SailthruFallbackDocument, Video, VideosDocument } from 'api';
import { initializeApollo } from 'lib/graphql/client';
import { truncateBySentences } from 'lib/util';
import { isEmpty, some, sortBy } from 'lodash-es';

const apolloClient = initializeApollo();

type RecommendationVideo = Partial<Video> & {
  title: string;
  url: string;
  description: string;
  id: string;
};

export type Recommendation = {
  video: RecommendationVideo;
};

export type SailthruDataContentType = Record<string, Recommendation[]>;

export enum SailthruSections {
  Recommendations = 'Recommendations',
  RecommendationsAI = 'RecommendationsAI',
  RecommendationsPsychology = 'RecommendationsPsychology',
  RecommendationsTEDEd = 'RecommendationsTEDEd',
  RecommendationsPopular = 'RecommendationsPopular',
  RecommendationsCountdown = 'RecommendationsCountdown',
  RecommendationsPodcast = 'RecommendationsPodcast',
  RecommendationsTrending = 'RecommendationsTrending',
  RecommendationsNewest = 'RecommendationsNewest'
}

export const sections = {
  [SailthruSections.Recommendations]: '9b4e837a-8318-11ee-911b-6a096f159873',
  [SailthruSections.RecommendationsAI]: '15375c30-b1bb-11ef-95a6-a2beda6870dc',
  [SailthruSections.RecommendationsPsychology]:
    '3a78a186-30b3-11ef-9854-9e7797660639',
  [SailthruSections.RecommendationsTEDEd]:
    '8fe0ecb2-b1bb-11ef-9465-42b3bfa427b2',
  [SailthruSections.RecommendationsPopular]:
    '932421fe-b1b2-11ef-8365-5a69f29232d2',
  [SailthruSections.RecommendationsCountdown]:
    'b3c7842e-b1bb-11ef-8365-5a69f29232d2',
  [SailthruSections.RecommendationsPodcast]:
    'ebc3e198-502b-11ef-a405-0273a0c42201',
  [SailthruSections.RecommendationsTrending]:
    'e499d75a-b1bb-11ef-95a6-a2beda6870dc',
  [SailthruSections.RecommendationsNewest]:
    '4b17e0d0-d3ef-11ef-af35-eacb2f1c3c9a'
};

type SailthruItem = {
  url: string;
  title: string;
  description: string;
  views: number;
};

const getVideosInfo = async (videosIds: number[]): Promise<VideoEdge[]> => {
  const { data } = await apolloClient.query({
    query: VideosDocument,
    variables: { id: videosIds }
  });

  return data?.videos?.edges ?? [];
};

/**
 * Extracts talk IDs from an array of Sailthru data objects.
 *
 * This function takes an array of data objects that contain URLs in the format
 * "https://www.ted.com/talks/{id}" and extracts the numeric ID from each URL.
 *
 * @param {Array} dataObjects - Array of objects containing a 'url' property
 * @returns {Array} - Array of numeric talk IDs
 *
 * @example
 * const data = [
 *   { url: 'https://www.ted.com/talks/123' },
 *   { url: 'https://www.ted.com/talks/456' }
 * ];
 * const ids = extractTalkIds(data); // returns [123, 456]
 *
 * @note If the URL format changes from "https://www.ted.com/talks/{id}",
 *       this function will need to be updated.
 * @note Returns an empty array if the input array is empty.
 */
const extractTalkIds = (dataObjects: SailthruItem[]): number[] => {
  if (!Array.isArray(dataObjects) || !dataObjects.length) return [];

  /**
   * The regex pattern ^https?:\/\/(?:www\.)?ted\.com\/talks\/(\d+) ensures:
   * URL starts with http(s)://
   * Optional www.
   * Domain is ted.com
   * Path starts with /talks/
   * ID is numeric only
   */
  const TED_URL_PATTERN = /^https?:\/\/(?:www\.)?ted\.com\/talks\/(\d+)/;

  return dataObjects
    .map(obj => {
      if (!obj?.url) return NaN;

      // Extract the numeric ID from the URL
      const match = obj.url.match(TED_URL_PATTERN);
      if (!match) return NaN;

      const [, id] = match;
      return parseInt(id, 10);
    })
    .filter(id => !isNaN(id));
};

export const formatSailthruData = async (
  data: SailthruItem[]
): Promise<Recommendation[]> => {
  if (!Array.isArray(data)) return [];

  const sortedRecommendations = sortBy(data, ['views']);
  const videosIds = extractTalkIds(sortedRecommendations);
  const videosInfo = await getVideosInfo(videosIds);

  const formattedData = sortedRecommendations.map(item => {
    if (!item?.url) return null;

    const { title, url, description } = item;
    const formattedDescription = truncateBySentences(description, 2);
    const urlParts = url.split('/');
    const id = urlParts[urlParts.length - 1];

    const videoInfo = videosInfo?.find(video => video?.node?.id === id);

    if (
      !videoInfo?.node ||
      !videoInfo.node.duration ||
      !videoInfo.node.primaryImageSet ||
      !videoInfo.node.slug
    ) {
      return null;
    }

    const result: Recommendation = {
      video: {
        ...videoInfo.node,
        title,
        url,
        description: formattedDescription,
        id
      }
    };

    return result;
  });

  return formattedData.filter((item): item is Recommendation => item !== null);
};

export const fetchSailthruData = async (): Promise<SailthruDataContentType> => {
  const fetchRequests = Object.values(SailthruSections).map(
    async sectionKey => {
      const sectionId = sections[sectionKey];
      const response = await fetch(
        `https://api.sail-personalize.com/v1/personalize?sections=${sectionId}`,
        {
          headers: {
            Authorization: `Bearer ${process.env.SAILTHRU_KEY}`
          }
        }
      );
      const data = await response.json();
      const recommendations =
        data?.sections?.[sectionId].json &&
        JSON.parse(data?.sections?.[sectionId].json);

      const formattedData = await formatSailthruData(recommendations);
      return { sectionKey, data: formattedData };
    }
  );

  const results = await Promise.all(fetchRequests);

  return results.reduce<SailthruDataContentType>(
    (acc, { sectionKey, data }) => {
      acc[sectionKey] = data;
      return acc;
    },
    {} as SailthruDataContentType
  );
};

export const getSailthruFallbackData = async (
  sailthruDataContent: SailthruDataContentType
) => {
  const sailthruFallback = {
    [SailthruSections.RecommendationsAI]: [],
    [SailthruSections.RecommendationsPsychology]: [],
    [SailthruSections.RecommendationsTEDEd]: [],
    [SailthruSections.RecommendationsCountdown]: [],
    [SailthruSections.RecommendationsTrending]: [],
    [SailthruSections.RecommendationsNewest]: [],
    [SailthruSections.RecommendationsPodcast]: [],
    [SailthruSections.RecommendationsPopular]: [],
    [SailthruSections.Recommendations]: []
  };

  if (some(Object.values(sailthruDataContent), section => isEmpty(section))) {
    const sailthruFallbackQueryResult = await apolloClient.query({
      query: SailthruFallbackDocument
    });

    if (isEmpty(sailthruFallbackQueryResult?.data)) {
      return sailthruFallback;
    }

    const {
      trendingTalksAi,
      trendingTalksPsychology,
      trendingTalksTEDEd,
      trendingTalksCountdown,
      trendingTalksTedx,
      newestTalks,
      podcasts
    } = sailthruFallbackQueryResult.data;

    // TODO: Still missing the popular and Recommended for you section need to check which query to use on these sections
    sailthruFallback[SailthruSections.RecommendationsAI] =
      trendingTalksAi?.videos?.nodes || [];
    sailthruFallback[SailthruSections.RecommendationsPsychology] =
      trendingTalksPsychology?.videos?.nodes || [];
    sailthruFallback[SailthruSections.RecommendationsTEDEd] =
      trendingTalksTEDEd?.videos?.nodes || [];
    sailthruFallback[SailthruSections.RecommendationsCountdown] =
      trendingTalksCountdown?.videos?.nodes || [];
    sailthruFallback[SailthruSections.RecommendationsTrending] =
      trendingTalksTedx?.videos?.nodes || [];
    sailthruFallback[SailthruSections.RecommendationsNewest] =
      newestTalks?.nodes || [];
    sailthruFallback[SailthruSections.RecommendationsPodcast] =
      podcasts?.videos?.nodes || [];
  }

  return sailthruFallback;
};
