import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { formatSailthruData } from 'lib/sailthru';
import storage from 'local-storage-fallback';
import { parseCookies } from 'nookies';

const usePersonalizedContent = (
  sectionId: string,
  options: {
    enabled?: boolean;
    talkId?: string;
  } = {}
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): UseQueryResult<any> => {
  const { enabled = true, talkId = null } = options;
  const sailthru_hid = parseCookies().sailthru_hid;

  return useQuery({
    queryKey: [`personalized-content-${sectionId}`, sectionId],
    queryFn: async () => {
      try {
        if (!sectionId) {
          return [];
        }
        let query = `?sections=${sectionId}`;
        const sailthruCookieValue = storage.getItem('sailthru_hid');

        if (sailthru_hid || sailthruCookieValue) {
          const value = sailthru_hid ?? sailthruCookieValue;
          query += `&userIdKey=hid&userIdValue=${value}`;
        }

        if (talkId) {
          query += `&vars=${encodeURIComponent(JSON.stringify({ vars: { context_key: `https://www.ted.com/talks/${talkId}` } }))}`;
        }

        const response = await fetch(
          `https://api.sail-personalize.com/v1/personalize${query}`,
          {
            headers: {
              Authorization: `Bearer ${process.env.NEXT_PUBLIC_SAILTHRU_KEY}`
            }
          }
        );

        const data = await response.json();

        // Safety check to ensure the section exists
        if (!data.sections || !data.sections[sectionId]) {
          console.warn(`No data found for section: ${sectionId}`);
          return [];
        }
        const recommendations =
          (data.sections[sectionId].json.length > 0 &&
            JSON.parse(data.sections[sectionId].json)) ??
          [];
        const formattedData = await formatSailthruData(recommendations);

        return formattedData;
      } catch (err) {
        console.error(
          `Error fetching personalized content for section ${sectionId}:`,
          err
        );
        return [];
      }
    },
    enabled,
    staleTime: 5 * 60 * 1000, // 5 minutes
    gcTime: 10 * 60 * 1000 // 10 minutes
  });
};

export default usePersonalizedContent;
